var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"md-layout"},[_c('div',{staticClass:"md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"},[_c('md-card',[_c('md-card-header',{staticClass:"card-header",attrs:{"data-background-color":"blue"}},[_c('div',{staticClass:"card-header-info"},[_c('h4',{staticClass:"title"},[_vm._v("Добавить категорию")])])]),_c('ValidationObserver',{ref:"addCategoryForm",attrs:{"slim":true}},[_c('md-card-content',[_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Название")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.category.name),callback:function ($$v) {_vm.$set(_vm.category, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"category.name"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Основное изображение")]),_c('md-file',{ref:"imageLoader",attrs:{"accept":"image/*"},on:{"md-change":function($event){_vm.category.icon = $event[0];
                  reset();}},model:{value:(_vm.iconName),callback:function ($$v) {_vm.iconName=$$v},expression:"iconName"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Оценочный вес")]),_c('md-input',{on:{"focus":reset},model:{value:(_vm.category.cover),callback:function ($$v) {_vm.$set(_vm.category, "cover", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"category.cover"}})],1)]}}])}),_c('ValidationProvider',{attrs:{"mode":"passive","slim":true,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
                  var reset = ref.reset;
return [_c('md-field',{class:{ error: errors.length }},[_c('ValidationError',{attrs:{"errors":errors}}),_c('label',[_vm._v("Порядок")]),_c('md-input',{attrs:{"type":"number","min":"0"},on:{"focus":reset,"change":_vm.validateOrder},model:{value:(_vm.category.order),callback:function ($$v) {_vm.$set(_vm.category, "order", $$v)},expression:"category.order"}})],1)]}}])}),_c('Treeselect',{attrs:{"options":_vm.categories,"value-consists-of":"LEAF_PRIORITY","placeholder":"Родительская категория"},model:{value:(_vm.category.parent_id),callback:function ($$v) {_vm.$set(_vm.category, "parent_id", $$v)},expression:"category.parent_id"}}),_c('md-checkbox',{model:{value:(_vm.category.is_active),callback:function ($$v) {_vm.$set(_vm.category, "is_active", $$v)},expression:"category.is_active"}},[_vm._v("Видимость")]),_c('div',{staticClass:"buttons-wrap"},[_c('md-button',{staticClass:"md-raised md-success",on:{"click":_vm.addNewCategory}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }