<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <md-card>
        <md-card-header data-background-color="blue" class="card-header">
          <div class="card-header-info">
            <h4 class="title">Добавить категорию</h4>
          </div>
        </md-card-header>
        <ValidationObserver ref="addCategoryForm" :slim="true">
          <md-card-content>
            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Название</label>
                <md-input v-model.trim="category.name" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Основное изображение</label>
                <md-file
                  ref="imageLoader"
                  v-model="iconName"
                  accept="image/*"
                  @md-change="
                    category.icon = $event[0];
                    reset();
                  "
                />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Оценочный вес</label>
                <md-input v-model.trim="category.cover" @focus="reset" />
              </md-field>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, reset }"
              mode="passive"
              :slim="true"
              rules="required"
            >
              <md-field :class="{ error: errors.length }">
                <ValidationError :errors="errors" />
                <label>Порядок</label>
                <md-input
                  v-model="category.order"
                  type="number"
                  min="0"
                  @focus="reset"
                  @change="validateOrder"
                />
              </md-field>
            </ValidationProvider>

            <Treeselect
              v-model="category.parent_id"
              :options="categories"
              value-consists-of="LEAF_PRIORITY"
              placeholder="Родительская категория"
            />

            <md-checkbox v-model="category.is_active">Видимость</md-checkbox>

            <div class="buttons-wrap">
              <md-button class="md-raised md-success" @click="addNewCategory">
                Сохранить
              </md-button>
            </div>
          </md-card-content>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

const initCategory = () => ({
  name: "",
  icon: "",
  cover: "",
  is_active: false,
  order: "",
  parent_id: null,
});

export default {
  components: {
    Treeselect,
  },

  data() {
    return {
      category: initCategory(),
      iconName: "", // hack for vee-validate
    };
  },

  computed: {
    ...mapGetters({
      categories: "additionalData/reworkedCategories",
    }),
  },

  async mounted() {
    this.$store.commit("SET_SHOW_LOADER", true);
    await this.getAdditionalData();
    this.$store.commit("SET_SHOW_LOADER", false);
  },

  methods: {
    ...mapActions("additionalData", ["getAdditionalData"]),
    ...mapActions("categories", ["createCategory"]),

    async addNewCategory() {
      const isValid = await this.$refs.addCategoryForm.validate();
      if (!isValid) {
        return;
      }

      let category = new FormData();

      category.append("name", this.category.name);
      category.append("icon", this.category.icon);
      category.append("cover", this.category.cover);
      category.append("is_active", +this.category.is_active);
      category.append("order", this.category.order);
      category.append("parent_id", this.category.parent_id ?? null);

      this.$store.commit("SET_SHOW_LOADER", true);
      let success = await this.createCategory(category);
      if (success) {
        this.initCategory();
      }
      this.$store.commit("SET_SHOW_LOADER", false);
    },

    initCategory() {
      this.$refs.imageLoader.clearField();
      this.category = initCategory();
    },

    validateOrder(event) {
      if (event.target.value < 0) {
        this.category.order = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons-wrap {
  display: flex;
  justify-content: space-between;
}
</style>
